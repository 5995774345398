import React from "react"
import Link from '../utils/link'

import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
		<section className='hero-banner hero-banner--error'>
			<div className='hero-banner__inner'>
				<div className='hero-banner__content'>
					<div>
						<h1 className='title'>404 Error</h1>
						<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
						<div className='hero-banner__buttons'>
							<Link className='btn' to='/'>Home</Link>
							<Link className='btn' to='/contact-us/'>Contact</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
  </>
)

export default NotFoundPage
